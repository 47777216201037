@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

.main-bg-text {
  font-family: "Helvetica", sans-serif;
}

.heading-text {
  font-family: "Work Sans", sans-serif;
  
}

.createdList ul {
  list-style-type: disc;
  padding-left: 20px; /* Optional: Adjust padding to position bullets as needed */
}



@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

.global-px {
  @apply mx-auto px-3 max-w-sm sm:max-w-lg md:max-w-3xl lg:max-w-5xl xl:max-w-6xl;
}


.main-px {
  @apply max-w-sm sm:max-w-lg md:max-w-3xl lg:max-w-5xl xl:max-w-6xl;
}

.bg-text-image {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 16.58%, rgba(255, 255, 255, 0) 72.38%);
  @apply bg-main bg-cover h-[646px] bg-center text-white
  
}

.bg-product-image {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 16.58%, rgba(255, 255, 255, 0) 72.38%);
  @apply bg-products bg-left-bottom  bg-center text-white
  
}

@keyframes scroll {
  0% {
    transform: translateY(0%);
  }
  
  100% {
    
    transform: translateY(calc(-100% + 150px));
  }
}

.scroll-container {
  position: relative;
  overflow: hidden;
}

.scroll-container img {
  position: absolute;
  /* animation: scroll 30s infinite linear; */
  transition: transform 0.5s ease;
}

.scroll-container:hover img  {
  animation: scroll 55s infinite linear;
}